body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100vw;
  height:100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backgroundParticles canvas{
  position:absolute;
  z-index:-1;
}

.logoCard {
  position: absolute;
  width: 80px; 
  height: 120px;
  border-radius: 8px;
}

.logoCardMini {
  position: absolute;
  width: 30px; 
  height: 45px; 
  border-radius: 6px;
}

.card-container {
  width: 128px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.card-container-horizontal {
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.poker-card-horizontal {
  font-family: 'Helvetica';
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 240px;
  border-radius: 8px;
  perspective: 1400px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  user-select: none;
}

.poker-card {
  font-family: 'Helvetica';
  font-size: 32px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 120px;
  border-radius: 8px;
  perspective: 1400px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  user-select: none;
}

.card-front, .card-back {
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.card-unselected {
  opacity: 0.25;
}

.card-front {
  background: #2196f3;
  background-repeat: 'repeat';
  background-position: 'centered';
  background-attachment: 'attached';
}

.card-front-selected {
  background: #2196f3;
}


.card-back {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
  color: #2196f3;
  background: #fff;
}

.card-pick, .card-pick-selected {
  display: flex;
  width: 50px;
  height: 75px;
  margin:8px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #2196f3;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.3);
}

.card-pick {
  background: #fff;
}

.card-pick-selected {
  background: #cfcfcf;
}

.animFadeIn {
  opacity: 0
}

.resultsChart {
  opacity: 0;
}